// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("/vercel/path3/web/src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-pages-404-js": () => import("/vercel/path3/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("/vercel/path3/web/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/vercel/path3/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-insekten-js": () => import("/vercel/path3/web/src/pages/info/insekten.js" /* webpackChunkName: "component---src-pages-info-insekten-js" */),
  "component---src-pages-info-pilze-js": () => import("/vercel/path3/web/src/pages/info/pilze.js" /* webpackChunkName: "component---src-pages-info-pilze-js" */),
  "component---src-pages-kontakt-js": () => import("/vercel/path3/web/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("/vercel/path3/web/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-referenzen-js": () => import("/vercel/path3/web/src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-ueber-uns-js": () => import("/vercel/path3/web/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path3/web/.cache/data.json")

