module.exports = [{
      plugin: require('/vercel/path3/web/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Holzschutz 24 AG","start_url":"/","display":"standalone","icon":"src/images/favicon-310x310.png"},
    },{
      plugin: require('/vercel/path3/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137158836-1","anonymize":true,"head":true},
    },{
      plugin: require('/vercel/path3/web/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Source Sans Pro:400,700"]}},
    },{
      plugin: require('/vercel/path3/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
